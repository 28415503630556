body {
    margin: 0;
}

.site-nav {
    display: none !important;
}

.page-content {
    padding: 0;
}

div.resume {
    padding: 0;
    font-size: 80%;
}